import React from "react";
import ErrorPage from "assets/images/404-page.png";
import PropTypes from "prop-types";
import { Button, Container, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { settingData } from "store/features/settingSlice";
import "assets/scss/page/_notfound.scss";
const PageNotFound = ({ getSettingData, t }) => {
  console.log("getSettingData: ", getSettingData);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo-not-found">
            <img
              src={getSettingData?.data.home_page_general_header_logo}
              alt="dashboard_logo"
              className="nav-brand"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <div className="page-not-found">
          <img src={ErrorPage} alt="404" className="not-found-image" />
          <h1 className="page-heading-center">
            {t("front_page.admin_not_found_oops_text")}
          </h1>
          <div className="error-page-text">
            {t("front_page.admin_not_found_text")}
          </div>
          <div className="primary-button">
            <Button onClick={handleClick}>
              {t("front_page.admin_back_to_home")}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

PageNotFound.propTypes = {
  from: PropTypes.string,
  t: PropTypes.func,
};

export { PageNotFound };
