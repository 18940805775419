import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import PropTypes from "prop-types";

const CounterSection = ({ counters }) => {
  return (
    <Container fluid className="mt-0 mt-sm-5">
      <Row className="py-4 counterBg">
        {counters.map((counter, index) => (
          <Col key={index} sm={4} className="counter my-1 my-sm-0 py-3 py-sm-0">
            <h1>
              <CountUp
                start={0}
                end={counter.end}
                decimals={1}
                duration={3}
                className="text-white"
              />{" "}
              <span>{counter.unit}</span>
            </h1>
            <p>{counter.title}</p>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

// Define prop types
CounterSection.propTypes = {
  counters: PropTypes.arrayOf(
    PropTypes.shape({
      end: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CounterSection;
