import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

import "assets/css/about.css";
import bannerImage from "assets/images/about-bg.png";
import { useGetAllTeamMemberData } from "hooks";
import { SeoHeader } from "common";

const About = ({ getSettingData, t }) => {
  const { data: getAllTeamMemberData } = useGetAllTeamMemberData(
    () => {},
    (error) => {
      console.log("In this api error occurs " + error.message);
    }
  );
  const teamMemberList = getAllTeamMemberData?.data?.team_member_list || [];

  return (
    <>
      <SeoHeader pageTitle={getSettingData?.data?.home_page_general_seo_title+" | "+ t("front_page.header_about_label")} seoTitle={getSettingData?.data?.about_setting_seo_title} description={getSettingData?.data?.about_setting_seo_description}  common_general_og_image = {getSettingData?.data?.about_setting_about_banner_image} fav={getSettingData?.data?.home_page_general_favicon_logo}/>
      <Container fluid className="p-0 position-relative">
        <div
          className="about-banner d-flex align-items-center gap-2 flex-column justify-content-center"
          style={{
            backgroundImage: `url(${
              getSettingData?.data?.about_setting_about_banner_image ||
              bannerImage
            })`,
          }}
        >
          <Col sm={12} className="text-center">
            <h1 className="title text-center w-100 text-white">
              {getSettingData?.data?.about_setting_about_banner_title}
            </h1>
          </Col>
          <Col sm={12} className="d-flex justify-content-center">
            <p className="title-para about-para text-white">
              {getSettingData?.data?.about_setting_about_banner_description}
            </p>
          </Col>
        </div>
      </Container>

      <Container fluid className="px-5 mt-5">
        <Row className="justify-content-between">
          <Col
            sm={12}
            md={8}
            className="who-text d-flex flex-column justify-content-center"
          >
            <h4>{getSettingData?.data?.about_setting_who_we_are_title}</h4>
            <h1 className="w-75 mt-2">
              {getSettingData?.data?.about_setting_who_we_are_subtitle}
            </h1>
            <p className="mt-3">
              {getSettingData?.data?.about_setting_who_we_are_description ||
                t(
                  "front_page.home_setting_about_who_we_are_description_static"
                )}
            </p>
          </Col>
          <Col className="who-img-div d-flex justify-content-end align-items-center">
            <img
              src={
                getSettingData?.data?.about_setting_who_we_are_image || ''
              }
              alt={(getSettingData?.data?.about_setting_who_we_are_image || '')
                  .split('/')
                  .pop()
                  .split('.')[0]}
            />
          </Col>
        </Row>
      </Container>

      {/* our mission */}
      <Container fluid className="mt-5">
        <Row>
          <Col
            md={6}
            xl={6}
            xxl={6}
            className="om-img-div p-0 d-flex align-items-center"
          >
            <img
              src={
                getSettingData?.data?.about_setting_our_mission_image || ''
              }
              alt={(getSettingData?.data?.about_setting_our_mission_image || '')
                .split('/')
                .pop()
                .split('.')[0]}
            />
          </Col>
          <Col
            md={6}
            xl={6}
            xxl={6}
            className="om-text p-5 d-flex flex-column justify-content-center pt-sm-0 pt-md-5 pb-sm-0 pb-md-5"
          >
            <h4>{getSettingData?.data?.about_setting_our_mission_title}</h4>
            <h1 className="w-75 mt-xl-3">
              {getSettingData?.data?.about_setting_our_mission_subtitle}
            </h1>
            <p className="mt-xl-4">
              {getSettingData?.data?.about_setting_our_mission_description ||
                t(
                  "front_page.home_setting_about_our_mission_description_static"
                )}
            </p>
          </Col>
        </Row>
      </Container>

      {/* our vision */}
      <Container fluid className="mt-5 ov-div">
        <Row>
          <Col
            md={6}
            xl={6}
            xxl={6}
            className="om-text p-5 d-flex flex-column justify-content-center pt-sm-0 pt-md-5 order-1 order-md-0"
          >
            <h4>{getSettingData?.data?.about_setting_our_vision_title}</h4>
            <h1 className="w-75 mt-xl-3">
              {getSettingData?.data?.about_setting_our_vision_subtitle}
            </h1>
            <p className="mt-xl-4">
              {getSettingData?.data?.about_setting_our_vision_description ||
                t(
                  "front_page.home_setting_about_our_vision_description_static"
                )}
            </p>
          </Col>
          <Col
            md={6}
            xl={6}
            xxl={6}
            className="om-img-div p-0 d-flex align-items-center justify-content-end order-0 order-md-1"
          >
            <img
              src={
                getSettingData?.data?.about_setting_our_vision_image || ''
              }
              alt={(getSettingData?.data?.about_setting_our_vision_image || '')
                .split('/')
                .pop()
                .split('.')[0]}
            />
          </Col>
        </Row>
      </Container>

      {/* our team */}
      <Container fluid className="mt-3 mt-sm-5 mb-5">
        <Row className="pt-0 pt-md-3 pt-xl-5">
          <Col className="ot-heading">
            <h4 className="text-center">
              {getSettingData?.data?.about_setting_our_team_title}
            </h4>
            <h1 className="mt-xl-3 text-center ot-sub-heading">
              {getSettingData?.data?.about_setting_our_team_subtitle}
            </h1>
          </Col>
        </Row>

        {teamMemberList.length !== 0 && (
          <Row className="mt-5">
            <Col className="mt-0 mt-lg-5">
              <Row className="d-flex justify-content-center gap-5 flex-wrap">
                {/* member */}
                {teamMemberList.map((teamMember, index) => (
                  <Col
                    key={index}
                    sm={5}
                    lg={3}
                    className="d-flex justify-content-center align-items-center flex-column member"
                  >
                    <div className="team-bg p-5">
                      <img
                        src={teamMember.image}
                        alt={(teamMember.image || '')
                          .split('/')
                          .pop()
                          .split('.')[0]}
                      />
                    </div>
                    <h3 className="text-center mt-4">
                      {teamMember.first_name + " " + teamMember.last_name}
                    </h3>
                    <h4>{teamMember.designation}</h4>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

About.propTypes = {
  getSettingData: PropTypes.shape({
    data: PropTypes.shape({
      about_setting_about_banner_image: PropTypes.string,
      about_setting_about_banner_title: PropTypes.string,
      about_setting_about_banner_description: PropTypes.string,
      about_setting_who_we_are_title: PropTypes.string,
      about_setting_who_we_are_subtitle: PropTypes.string,
      about_setting_who_we_are_description: PropTypes.string,
      about_setting_who_we_are_image: PropTypes.string,
      about_setting_our_mission_image: PropTypes.string,
      about_setting_our_mission_title: PropTypes.string,
      about_setting_our_mission_subtitle: PropTypes.string,
      about_setting_our_mission_description: PropTypes.string,
      about_setting_our_vision_title: PropTypes.string,
      about_setting_our_vision_subtitle: PropTypes.string,
      about_setting_our_vision_description: PropTypes.string,
      about_setting_our_vision_image: PropTypes.string,
      about_setting_our_team_title: PropTypes.string,
      about_setting_our_team_subtitle: PropTypes.string,
    }),
  }),
};

export default About;
