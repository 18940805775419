import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  front_logo: "",
  favicon_icon: "",
};
export const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {
    addSetting: (state, { payload }) => {
      state.front_logo = payload.front_logo;
      state.favicon_icon = payload.favicon_icon;
      return state;
    },
    clearSetting: () => initialState,
  },
});
export const { addSetting, clearSetting } = settingSlice.actions;
export const settingData = (state) => state.setting;
export default settingSlice.reducer;
