import React, { useState, useEffect } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import "assets/css/style.css";
import logo from "assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes
import { Link as ScrollLink, scroller } from "react-scroll";

function Header({ getSettingData, t }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50 || menuOpen) {
      // Adjust the value based on when you want to change the class
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setActiveLink(location.pathname); // Update activeLink when location changes
  }, [location.pathname]);
  const handleSelect = (path) => {
    setActiveLink(path);
    setMenuOpen(false); // Close the menu after selecting a link
    document.body.classList.remove("no-scroll");
  };

  const handleToggle = () => {
    const newMenuOpen = !menuOpen;
    setMenuOpen(newMenuOpen);
    setScrolled(newMenuOpen);
    document.body.classList.toggle("no-scroll");
  };
  const scrollToDownloadSection = () => {
    if (location.pathname === "/") {
      scroller.scrollTo("download", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    } else {
      navigate("/#download");
    }
  };
  // Ensure that getSettingData and its nested properties are safely accessed
  const headerLogoUrl = getSettingData?.data?.common_header_logo || logo;

  return (
    <Container fluid className="px-lg-5 navbar-colorize navbar-fixed-top">
      <Navbar
        className=""
        expand="lg"
        expanded={menuOpen}
        onToggle={handleToggle}
      >
        <Container fluid className=" p-lg-0">
          <Navbar.Brand href="/">
            <img
              src={headerLogoUrl}
              alt="Logo"
              className="d-d-inline-block logoImg"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="p-0"
          />
          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            <Nav className="nav">
              <Link
                to="/"
                className={`nav_link ${
                  activeLink === "/" ? "header-active-class" : ""
                }`}
                onClick={() => handleSelect("/")}
              >
                {t("front_page.header_home_label")}
              </Link>
              <Link
                to="/about"
                className={`nav_link ${
                  activeLink === "/about" ? "header-active-class" : ""
                }`}
                onClick={() => handleSelect("/about")}
              >
                {t("front_page.header_about_label")}
              </Link>
              <Link
                to="/contact"
                className={`nav_link ${
                  activeLink === "/contact" ? "header-active-class" : ""
                }`}
                onClick={() => handleSelect("/contact")}
              >
                {t("front_page.header_contact_label")}
              </Link>
              <Link
                to="/faq"
                className={`nav_link ${
                  activeLink === "/faq" ? "header-active-class" : ""
                }`}
                onClick={() => handleSelect("/faq")}
              >
                {t("front_page.header_faq_label")}
              </Link>
              <Link to="#" className="nav_link">
                <Button className="loginBtn bg-transparent">
                  {" "}
                  {t("front_page.header_login_label")}
                </Button>
              </Link>
              <ScrollLink
                to="download"
                className="nav_link"
                onClick={scrollToDownloadSection}
              >
                <Button className="downloadBtn">
                  {t("front_page.header_download_label")}
                </Button>
              </ScrollLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

// PropTypes validation
Header.propTypes = {
  getSettingData: PropTypes.shape({
    data: PropTypes.shape({
      common_header_logo: PropTypes.string,
      // Add other properties as needed
    }),
  }),
  t: PropTypes.func,
};

export default Header;
