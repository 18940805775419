import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import 'assets/css/faq.css';
import { Container, Row, Col, Nav, Accordion, Form } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { useGetAllFaqData } from 'hooks';
import { SeoHeader } from 'common';

function Faq({ getSettingData, t }) {
  const [faqData, setFaqData] = useState([]);
  const [activeKey, setActiveKey] = useState(0);
  const [searchText, setSearchText] = useState(localStorage.faqSearch && localStorage.faqSearch !== '' ? JSON.parse(localStorage.faqSearch).searchText : '');
  const [searchTextFilter, setSearchTextFilter] = useState(localStorage.faqSearch && localStorage.faqSearch !== '' ? JSON.parse(localStorage.faqSearch).searchTextFilter : '');
  const debounceTimeoutRef = useRef(null);
  const { refetch } = useGetAllFaqData(
    [searchTextFilter],
    (res) => {
      setFaqData(res?.data || []);
      setActiveKey(faqData?.category_type?.length > 0 ? faqData?.category_type[0].faq_category_id : '0');
    },
    (error) => {
      console.log('In this api error occurs ' + error.message);
    }
  );

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };
  useEffect(() => {
    if (faqData?.category_type?.length > 0) {
      setActiveKey(faqData?.category_type[0].faq_category_id.toString());
    }
  }, [faqData]);

  /**
   * !This block will call on type in serachbox
   */
  const handleSearch = (search_text) => {
    const value = search_text.target.value.trim();
    setSearchText(value);
    // Clear previous timeout if it exists
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      if (value.length >= 2) {
        setSearchTextFilter(value);
      } else {
        setSearchTextFilter('');
      }
      refetch();
    }, 500);
  };

  useEffect(() => {
    let faqSearch = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
    };
    localStorage.faqSearch = JSON.stringify(faqSearch);
  }, [searchText, searchTextFilter]);
  return (
    <>
      <SeoHeader
        pageTitle={getSettingData?.data?.home_page_general_seo_title + ' | ' + t('front_page.header_faq_label')}
        seoTitle={getSettingData?.data?.faq_setting_faq_title}
        description={getSettingData?.data?.faq_setting_faq_description}
        common_general_og_image={getSettingData?.data?.home_page_common_general_og_image}
        fav={getSettingData?.data?.home_page_general_favicon_logo}
      />
      <Container fluid className="mb-5 faq-container">
        <Row className="mt-2 mt-sm-5">
          <Col sm={12} className="title text-center">
            {getSettingData?.data?.faq_setting_faq_title}
          </Col>
          <Col sm={12} className="d-flex justify-content-center mt-4">
            <p className="title-para">{getSettingData?.data?.faq_setting_faq_description}</p>
          </Col>
          <Col sm={12} md={12} xl={12} className="px-5">
            <Form.Group controlId="search" className="filter-field-space ">
              <Form.Control
                placeholder="Search"
                className="filter-column form-field search-cls"
                style={{
                  border: '2px solid #7532c6',
                  borderRadius: '10px',
                  boxShadow: '0 0 5px 0 #00000040',
                  color: '#121212',
                  fontSize: '16px',
                  fontWeight: '300',
                  height: '50px',
                  padding: '18px',
                  width: '20%',
                  float: 'right',
                }}
                onKeyUp={handleSearch}
                onChange={handleSearch}
                value={searchText}
              />
            </Form.Group>
          </Col>
        </Row>
        {faqData.length !== 0 && (
          <Tab.Container id="left-tabs-example" defaultActiveKey="1" activeKey={activeKey} onSelect={handleSelect}>
            <Row className="mt-4 px-4">
              <Col sm={12} md={12} xl={2}>
                <Nav className="flex-xl-column flex-lg-row  align-items-start faq-tab justify-content-center justify-content-xl-start">
                  {faqData?.category_type.map((category) => (
                    <Nav.Item key={category.faq_category_id}>
                      <Nav.Link eventKey={category.faq_category_id} className={`faq-link ${activeKey === category.faq_category_id ? 'tab-active-class' : ''}`}>
                        {category.category_name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12} md={12} xl={10} className="px-4 mt-5 mt-xl-0">
                <Tab.Content>
                  {faqData?.category_type.map((category) => (
                    <Tab.Pane key={category.faq_category_id} eventKey={category.faq_category_id}>
                      {category.faq.length === 0 ? (
                        <div className="no-data-message">
                          <h2>{t('front_page.no_faqs_available')}</h2>
                        </div>
                      ) : (
                        <Accordion defaultActiveKey="0">
                          {category.faq.map((faqItem) => (
                            <Accordion.Item key={faqItem.faq_id} eventKey={faqItem.faq_id.toString()} className="mt-3">
                              <Accordion.Header className="acc-header">{faqItem.question ? faqItem.question : 'no'}</Accordion.Header>
                              <Accordion.Body>{faqItem.answer}</Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      )}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        )}
      </Container>
    </>
  );
}

Faq.propTypes = {
  getSettingData: PropTypes.shape({
    data: PropTypes.shape({
      faq_setting_faq_title: PropTypes.string,
      faq_setting_faq_description: PropTypes.string,
    }),
  }),
  t: PropTypes.func,
};

export default Faq;
