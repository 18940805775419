import React from "react";
import { Outlet } from "react-router-dom";

import { PublicLayout } from "common/layouts";

const PublicRoute = ({ getSettingData, t }) => {
  return (
    <PublicLayout getSettingData={getSettingData} t={t}>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
