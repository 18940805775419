import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClientProvider, QueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import PagesRoutes from "routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "assets/css/style.css";
import { persistor, store } from "store";
import { setAuthToken, setAuthType } from "libs";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const { t } = useTranslation();
  console.log(t("front_page.header_home_label"));

  window.ononline = (event) => {
    window.location.reload(true);
  };

  const handleOnBeforeLift = () => {
    if (
      store.getState().user.accessToken !== undefined &&
      store.getState().user.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
    }
    setAuthType("front");
  };

  return (
    <Suspense>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={handleOnBeforeLift}
        >
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <PagesRoutes t={t} />
              <ToastContainer />
            </BrowserRouter>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
export default App;
