import * as Yup from "yup";

const validationSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, t("front_page.contact_us_form_name_validation_min"))
      .max(50, t("front_page.contact_us_form_name_validation_max"))
      .required(t("front_page.contact_us_form_name_validation_required")),
    email: Yup.string()
      .email(t("front_page.contact_us_form_email_validation_email"))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\s)$/,
        t("front_page.contact_us_form_email_validation_email")
      )
      .required(t("front_page.contact_us_form_email_validation_required")),
    phone: Yup.string()
      .matches(
        /^[0-9]+$/,
        t("front_page.contact_us_form_mobile_number_validation_match")
      )
      .min(10, t("front_page.contact_us_form_mobile_number_validation_min"))
      .max(10, t("front_page.contact_us_form_mobile_number_validation_max"))
      .required(
        t("front_page.contact_us_form_mobile_number_validation_required")
      ),
    question: Yup.string()
      .min(10, t("front_page.contact_us_form_question_validation_min"))
      .required(t("front_page.contact_us_form_question_validation_required")),
  });
export default validationSchema;
