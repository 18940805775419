import { createSlice } from "@reduxjs/toolkit";
import { setAuthToken } from "libs/HttpClient";

const initialState = {
  user: "",
  isUserLogin: false,
  accessToken: "",
};

export const authSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      state.user = payload.userData;
      state.isUserLogin = true;
      state.accessToken = payload.accessToken;
      setAuthToken(payload.accessToken);
      return state;
    },
    updateUserData: (state, { payload }) => {
      state.user = payload.userData;
      return state;
    },
    logoutSuccess: () => initialState,
  },
});
export const { loginSuccess, logoutSuccess, updateUserData } =
  authSlice.actions;
export const loggedUser = (state) => state.user;
export default authSlice.reducer;
