import { FaqService } from "api";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetAllFaqData = ([searchText],onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['all_faq_data', searchText],FaqService.getFaqData,
    {
      onSuccess,
      onError,
    }
  );
};

export { useGetAllFaqData };
