import React from "react";
import PropTypes from "prop-types";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "./PublicRoute";
import Main from "pages/Homepage/Main";
import About from "pages/AboutPage/About";
import Contact from "pages/ContactPage/Contact";
import Faq from "pages/FAQ/Faq";
import "locales/i18n";
import { useGetSettingData } from "hooks";
import { PageNotFound } from "common/components/PageNotFound";
const CMSViewPage = React.lazy(() => import("../pages/CmsPage/CMSViewPage"));

const PagesRoutes = ({ t }) => {
  const location = useLocation();

  // Determine route name based on location.pathname
  const route_name =
    location.pathname === "/" ||
    location.pathname.startsWith("/page/") ||
    location.pathname.startsWith("/company")
      ? "home"
      : location.pathname.substr(1);

  const { data: getSettingData } = useGetSettingData(
    route_name,
    () => {},
    (error) => {
      if (error.data.redirect == true) {
        console.log("Error occurred: ", error.message);
      }
    }
  );

  return (
    <>
      <Routes>
        <Route element={<PublicRoute getSettingData={getSettingData} t={t} />}>
          <Route
            exact
            path="/"
            element={<Main getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/about"
            element={<About getSettingData={getSettingData} t={t} />}
          />
          <Route exact path="/page/:slug" element={<CMSViewPage getSettingData={getSettingData} t={t} />} />
          <Route
            exact
            path="/contact"
            element={<Contact getSettingData={getSettingData} t={t} />}
          />
          <Route
            exact
            path="/faq"
            element={<Faq getSettingData={getSettingData} t={t} />}
          />
        </Route>
        <Route>
          <Route
            path="*"
            element={<PageNotFound getSettingData={getSettingData} t={t} />}
            from="front"
          />{" "}
        </Route>
      </Routes>
    </>
  );
};

PagesRoutes.propTypes = {
  t: PropTypes.func,
};

export default PagesRoutes;
