import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { ContactService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useSendContactQuery = (onSuccess, onError = onDefaultError) => {
  return useMutation(ContactService.sendContactQuery, {
    onSuccess,
    onError,
  });
};

export { useSendContactQuery };
