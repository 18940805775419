import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { settingData } from "store/features/settingSlice";

const SeoHeader = ({ pageTitle, seoTitle, description, fav, common_general_og_image }) => {
  const getSettingData = useSelector(settingData);

  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link
        rel="shortcut icon"
        href={`${fav}`}
        type="image/x-icon"
      />
      <link rel="shortcut icon" href={`${fav}`} />
      <meta name="googlebot" content="index,follow" />
      <meta name="yahoobot" content="index, follow" />
      <meta name="bingbot" content="index,follow" />
      <title>{pageTitle}</title>
      <meta name="title" content={seoTitle} />
      <meta name="description" content={description} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={common_general_og_image || getSettingData?.data?.home_page_common_general_og_image} />
      <link
        rel="icon"
        type="image/x-icon"
        href={`${fav}`}
      />
    </Helmet>
  );
};
SeoHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SeoHeader;
