import client from "../libs/HttpClient";
class ContactService {
  static getCMSData(params) {
    return client.get("get-cms/" + params);
  }
  static sendContactQuery(request) {
    return client.post("contact/send-contact-query", request, {
      //   headers: {
      //     "Content-type": "multipart/form-data",
      //   },
    });
  }
}
export { ContactService };
