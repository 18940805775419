import { CmsService } from "api";
import { toast } from "react-toastify";

const { useQuery } = require("react-query");

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetCmsData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["cms_data", [params]],
    () => {
      return CmsService.getCMSData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useGetCmsData };
